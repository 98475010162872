<template>
  <Grid
    type="webhook"
    title="Webhooks"
    :multiple="true"
    :isMain="isMain"
    :visible="['webhook_preview', 'webhook_name', 'webhook_event', 'webhook_endpoint']"
  >
  </Grid>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import BodyMixin from '../mixins/Body'

export default {
  name: 'WebhooksPage',
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  mixins: [BodyMixin],
  components: {
    Grid
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
