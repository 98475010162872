<template>
  <Page full>
    <webhook-body />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import WebhookBody from '../bodies/Webhooks.vue'

export default {
  mixins: [PageMixin],
  name: '',
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  components: {
    WebhookBody
  },
  props: {},
  mounted() {},
  beforeUnmount() {}
}
</script>
